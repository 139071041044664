//Here we will have the orchestrator which will calculate the scores and populate the scores one by one of each screen

import { PropertyDetailAPIResponseInterface } from "../../property/interfaces/propertyDetail.interface";
import { screen_property_overview_score } from "./screen_1";
import { screen_reservation_score } from "./screen_2";
import { screen_parking_score } from "./screen_3";
import { screen_guest_policies_score } from "./screen_4";
import { screen_location_score } from "./screen_5";
import { screen_contacts_score } from "./screen_6";

/*******************************************************************************************************************/
/*in case of zeevou - pparking will be null, property overview accomodation and property overview wifi will be null*/

export function totalScreensScore (property_data: PropertyDetailAPIResponseInterface ) {
    let score: PropertyScoreInterface = {
        property_id: property_data.data_link_id,
        property_total_score:{
            calculated_score:0,
            required_score:41
        },
        screen_property_overview: screen_property_overview_score(property_data),
        screen_reservation: screen_reservation_score(property_data),
        screen_parking: screen_parking_score(property_data), // null in case of zeevou
        screen_guest_policies: screen_guest_policies_score(property_data),
        screen_location: screen_location_score(property_data),
        screen_contacts: screen_contacts_score(property_data)
    }

    const parkingScreenTotalScore  =  score.screen_parking ? score.screen_parking._total_score.calculated_score : 0 

    score.property_total_score.calculated_score = score.screen_property_overview._total_score.calculated_score + score.screen_reservation._total_score.calculated_score + score.screen_guest_policies._total_score.calculated_score +
    score.screen_contacts._total_score.calculated_score +
    score.screen_location._total_score.calculated_score + parkingScreenTotalScore
    return score

}

