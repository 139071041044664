import { PropertyDetailAPIResponseInterface } from '../../property/interfaces/propertyDetail.interface';
import * as propertyScoreSchema from '../schema/propertyDetailDefaultScore.schema.json';

export function screen_location_score(propertyData: PropertyDetailAPIResponseInterface) {

    const PROPERTY_SCORE_SCHEMA = JSON.parse(JSON.stringify(propertyScoreSchema))

    const screenLocation = {...PROPERTY_SCORE_SCHEMA.screen_location}
    
    if (propertyData.location.lat) {
        screenLocation.lat.calculated_score = 1
    }
    if (propertyData.location.lng) {
        screenLocation.lng.calculated_score = 1
    }
    if (propertyData.location.country) {
        screenLocation.country.calculated_score = 1
    }
    if (propertyData.location.city) {
        screenLocation.city.calculated_score = 1
    }
    if (propertyData.location.complete_address) {
        screenLocation.complete_address.calculated_score = 1
    }
    if (propertyData.location.postal_code) {
        screenLocation.postal_code.calculated_score = 1
    }
    if (propertyData.location.time_zone) {
        screenLocation.time_zone.calculated_score = 1
    }
    if (propertyData.transportation.options.length) {
        screenLocation.transportation.calculated_score = 1
    }
   

/*
    const { lat, lng, country, city, complete_address, postal_code, time_zone, transportation } = screenLocation

    screenLocation._total_score.calculated_score = lat.calculated_score + lng.calculated_score + country.calculated_score + city.calculated_score + complete_address.calculated_score + postal_code.calculated_score + time_zone.calculated_score+ transportation.calculated_score

*/

    screenLocation._total_score.calculated_score = screenLocation.lat.calculated_score + screenLocation.lng.calculated_score + screenLocation.country.calculated_score + screenLocation.city.calculated_score + screenLocation.complete_address.calculated_score + screenLocation.postal_code.calculated_score + screenLocation.time_zone.calculated_score + screenLocation.transportation.calculated_score

    return screenLocation

}