import { PropertyDetailAPIResponseInterface } from '../../property/interfaces/propertyDetail.interface';
import * as propertyScoreSchema from '../schema/propertyDetailDefaultScore.schema.json';

export function screen_parking_score(propertyData: PropertyDetailAPIResponseInterface) {

  const PROPERTY_SCORE_SCHEMA = JSON.parse(JSON.stringify(propertyScoreSchema))

  const screenParking = PROPERTY_SCORE_SCHEMA.screen_parking

  if (propertyData.integration_type === 'zeevou') {
    screenParking._total_score.calculated_score = 0
  }
  else {

    if (propertyData.parking.parking_type) {
      screenParking.parking_type.calculated_score = 1
    }
    if (propertyData.parking.parking_reservation) {
      screenParking.parking_reservation.calculated_score = 1
    }
    if (propertyData.parking.parking_is_private === false || propertyData.parking.parking_is_private === true) {
      screenParking.parking_is_private.calculated_score = 1
    }
    if (propertyData.parking.total_spots === 0 || propertyData.parking.total_spots) {
      screenParking.total_spots.calculated_score = 1
    }

    const { total_spots, parking_is_private, parking_reservation, parking_type } = screenParking

    screenParking._total_score.calculated_score = total_spots.calculated_score + parking_is_private.calculated_score + parking_reservation.calculated_score + parking_type.calculated_score
  }


  return screenParking

}