import React, { useRef } from 'react';
import "../styles/globals.css";
import type { AppProps } from "next/app";
import { ClerkProvider, useUser } from "@clerk/nextjs";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import LogRocket from "logrocket";
// import { SubscriptionProvider } from "use-stripe-subscription";
import * as Sentry from "@sentry/nextjs";
// import './api-docs.js';
import { store } from '../redux/store'
import { Provider } from 'react-redux'

const Scripts = () => {
  const { user } = useUser();
  useEffect(() => {
    if (user) {
      let email = user.emailAddresses.shift()?.emailAddress;
      try {
        LogRocket.identify(user.id, {
          email: email as string,
        });
        window["analytics"].identify(user.id, {
          email: email as string,
          name: user.fullName,
          created: user.createdAt?.toISOString(),
        });
        window["_cio"].identify(user.id, {
          email: email as string,
          name: user.fullName,
          created: user.createdAt?.toISOString(),
        });
      } catch (error) {
        console.log("error", error);
      }
    }
  }, [user]);
  useEffect(() => {
    LogRocket.init("q0lvvv/guest-guru");
    LogRocket.getSessionURL((sessionURL) => {
      Sentry.configureScope((scope) => {
        scope.setExtra("sessionURL", sessionURL);
      });
    });
  }, []);

  const scriptRef:any = useRef(null);

  useEffect(() => {
    // Your _cio.identify script
    if (user) {
      let email = user.emailAddresses.shift()?.emailAddress;
    const scriptContent = `
      _cio.identify({
        id: ${email},
        created_at: ${user.createdAt?.toISOString()},
        first_name: ${user.fullName},
      });
    `;

    // Create a script element
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = scriptContent;

    // Append the script to the body of the document
    document.body.appendChild(script);

    // Save a reference to the script element using the ref
    scriptRef.current = script;

    // Clean up function to remove the script when the component unmounts
    return () => {
      if (scriptRef.current) {
        document.body.removeChild(scriptRef.current);
      }
    };
  }
  }, []);
  return <></>;
};

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <Provider store={store}>
    {/* <SubscriptionProvider
      stripePublishableKey={process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY ? process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY : ''}
    > */}
      <ClerkProvider
        {...pageProps}
        appearance={
          {
            layout: {
              termsPageUrl: "https://guestguru.ai/terms-of-service",
            },
            elements: {
              socialButtonsBlockButton:
                "border-ggprimary hover:border-gghover",
              footerPagesLink:
                "text-ggprimary hover:text-gghover text-md normal-case focus:ring-0 focus:ring-0",
              formButtonPrimary:
                "bg-ggprimary hover:bg-gghover text-sm normal-case",
              footerActionLink:
                "text-ggprimary hover:text-gghover text-md normal-case focus:ring-0 focus:ring-0",
              formFieldAction:
                "text-ggprimary hover:text-gghover text-md normal-case focus:ring-0 focus:ring-0",
              formFieldInput:
                " border-ggprimary",
              dividerLine:
                " bg-ggprimary",
              phoneInputBox:
                " border-ggprimary",
            },

          }
        }
      >
        {process.env.NEXT_PUBLIC_VERCEL_ENV === "production" ? <Scripts /> : ""}
        <Component {...pageProps} />
        <ToastContainer limit={1} />
      </ClerkProvider>
    {/* </SubscriptionProvider> */}
    </Provider>
  );
}

export default MyApp;
