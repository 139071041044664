import { createSlice, nanoid } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { totalScreensScore } from './reducers/main';
const propertyDetailScoreInitialObject : PropertyScoreInterface = require("./schema/propertyDetailDefaultScore.schema.json")

//how would the slice look like initially

export interface PropertyRedux {
  propertyDetailScore: PropertyScoreInterface
}

const initialState: PropertyRedux  = {
  propertyDetailScore: propertyDetailScoreInitialObject
};
 //this would call the orchestrators
export const PropertyScoreSlice = createSlice({
  name: 'property-score',
  initialState,
  // Redux Toolkit allows us to write "mutating" logic in reducers. It doesn't actually mutate the state because it uses the Immer library,
  // which detects changes to a "draft state" and produces a brand new immutable state based off those changes
  //state contains the current value in the state
  reducers: {
      //----------------------------------------//
      /** These action functions are for loader */
      
      //---------------------------------------------------//
      /***** These action functions are for property *******/
      setPropertyScoreReducer: (state, action: PayloadAction<any>) => {
      // console.log('rdx act set-property', state, action);
      const {payload, type} = action;
      const {propertyId, data: property} = payload;
      const calculated_score = totalScreensScore(property)
      state.propertyDetailScore = calculated_score
      }
      },
    })

// Action creators are generated for each case reducer function
export const {  setPropertyScoreReducer} = PropertyScoreSlice.actions

export default PropertyScoreSlice.reducer